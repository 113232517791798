


































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class TagList extends Vue {
  @Prop()
  public data!: any;
  @Prop()
  public type!: any;
  @Prop()
  public itemKey!: any;
  @Prop()
  public otherType!: any;

  public activeList: any = [];
  public detailBlurCheck: boolean = false;

  public created() {
    this.data.map((row: any, index: any) => {
      this.data[index][`active`] = false;
    });
    if (this.otherType === 'checkDetail') {
      this.data.map((row: any, index: any) => {
        this.data[index][`detail`] = '';
      });
    }
  }

  public itemClick(item: any, index: number) {
    if (this.detailBlurCheck) {
      return;
    }
    if (this.type === 'single_select') {
      if (this.data[index].active) {
        for (let i = 0; i <= this.data.length - 1; i++) {
          Vue.delete(this.data[i], 'active');
        }
      } else {
        for (let i = 0; i <= this.data.length - 1; i++) {
          Vue.delete(this.data[i], 'active');
        }
        Vue.set(this.data[index], 'active', true);
      }
    } else {
      if (this.data[index].active) {
        Vue.delete(this.data[index], 'active');
        Vue.set(this.data[index], 'active', false);
      } else {
        Vue.delete(this.data[index], 'active');
        Vue.set(this.data[index], 'active', true);
      }
    }
  }
  public itemDetailClick() {
    //
  }
  public inputBlur() {
    this.detailBlurCheck = true;
    setTimeout(() => {
      this.detailBlurCheck = false;
    }, 300);
  }
  public inputDetail(item: any, index: any) {
    Vue.delete(this.data[index], 'detail');
    Vue.set(this.data[index], 'detail', item);
  }

  @Watch('data')
  public dataUpdate() {
    this.activeList = [];
    this.data.filter((row: any) => {
      if (row.active) {
        if (this.otherType === 'checkDetail') {
          this.activeList.push({
            id: row.id,
            detail: row.detail.trim()
          });
          return;
        }
        this.activeList.push({
          id: row.id
        });
      }
    });
    this.$emit('cart-add-data', this.activeList, this.itemKey);
  }
}
