var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-list-wrap",class:{
    'check-detail': _vm.otherType === 'checkDetail'
  }},_vm._l((_vm.data),function(item,i){return _c('div',{key:i,staticClass:"item",class:[
      ("item-" + (item.id)),
      {
        'active': item.active
      }
    ],on:{"click":function($event){return _vm.itemClick(item, i)}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('div',{staticClass:"detail",on:{"click":function($event){$event.stopPropagation();return _vm.itemDetailClick.apply(null, arguments)}}},[_c('q-input',{staticClass:"textarea-style1",attrs:{"type":"textarea","placeholder":"세부사항 입력 (필수)","autogrow":""},on:{"input":function($event){return _vm.inputDetail(item.detail, i)},"blur":_vm.inputBlur},model:{value:(item.detail),callback:function ($$v) {_vm.$set(item, "detail", $$v)},expression:"item.detail"}})],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }